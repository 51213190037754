import $ from 'jquery';

const onboarding = () => {
  const setPageHeight = () => {
    const $fullPage = $('.full-page');
    $fullPage.height($fullPage.height() + $onboardingSteps.height());
  };

  const $onboardingSteps = $('#onboarding_steps');

  if ($onboardingSteps.hasClass('on')) {
    setPageHeight();
  }

  $('#onboarding_container').click(function(){
    if ($onboardingSteps.hasClass('off')) {
      $onboardingSteps.removeClass('off');
      $onboardingSteps.addClass('on');

      setPageHeight();
    } else {
      $onboardingSteps.removeClass('on');
      $onboardingSteps.addClass('off');
    }

    return false;
  });
};

export default onboarding;
