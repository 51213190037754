import $ from 'jquery';

const references = () => {
  $('#reference_entry_type').on('change', function () {
    const entryType = this.value;
    const pathname = window.location.pathname;

    let newLocation;
    if (pathname === '/references') {
      newLocation = '/references/new' + '?entry_type=' + entryType;
    } else {
      newLocation = window.location.pathname + '?entry_type=' + entryType;
    }

    window.Turbo.visit(newLocation);
  });

  $('#references-search').on('keyup', function () {
    const value = $(this).val().toLowerCase();

    $('#references-table .reference-row').filter(function () {
      const referenceValue = $(this).data('searchable-by').toLowerCase();

      $(this).toggle(referenceValue.includes(value));
    });
  });
};

export default references;
