import $ from 'jquery';
import autosize from 'autosize';
import 'select2';
import 'select2/dist/css/select2.min.css';
import Rails from '@rails/ujs';
import '@popperjs/core';
import { Alert } from 'bootstrap';
import '@oddcamp/cocoon-vanilla-js';
import _ from 'lodash/fp';
import '@hotwired/turbo-rails';

import './css/site.scss';
import onboarding from './lib/onboarding';
import references from './lib/references';

if (!window._rails_loaded) Rails.start();

const fadeOutFlash = () => {
  const alertNode = document.querySelector('.alert-dismissible');
  if (_.isNil(alertNode)) return;

  const alert = new Alert(alertNode);
  alert.close();
};

document.addEventListener('turbo:load', () => {
  autosize(document.querySelector('textarea'));
  onboarding();
  references();

  if ($('.select2').length === 0) {
    $('#reference_list_names').select2({ tags: true });
    $('#list_names').select2({ tags: true });
  }

  window.setTimeout(fadeOutFlash, 2000);
});
